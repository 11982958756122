import React, { FC } from 'react';
import { CheckCircle } from 'react-feather';

import { Anchor } from '../../../components/Anchor';
import { Button } from '../../../components/Button';
import { H4 } from '../../../components/Typography';
import { Container, HelpText, Loading } from '../../commonStyles';

interface P {
  downloadLink: string;
  onReset: VoidFunction;
}

export const SuccessContainer: FC<P> = (props) => {
  const { downloadLink, onReset } = props;

  return (
    <Container>
      <Loading className="flex flex-col items-center justify-center">
        <H4 className="mb-20 text-center">Your files have been Hypercited</H4>

        <CheckCircle color="#27AE60" />

        <HelpText className="text-center" style={{ marginTop: '1rem', padding: 40 }}>
          File will automatically download.
          <br />
          Didnt get the file? Download <Anchor href={downloadLink}>file</Anchor>.
          <br />
          <br />
          You must open this document in a PDF viewer like Adobe Acrobat, or any other viewer capable of supporting
          attachments. Web browsers and Mac Preview are NOT supported, as they do not support attachments.
          <br />
          <br />
          Hint: if our logo is visible on the last page of your submission, but you are unable to observe any links,
          that means you are viewing the brief in an incompatible viewer.
        </HelpText>

        <div className="flex flex-row items-center justify-between px-4 mt-4">
          <Button variant="primary" onClick={onReset}>
            Start Over
          </Button>
        </div>
      </Loading>
    </Container>
  );
};
