import ProgressBar from '@ramonak/react-progress-bar';
import { isNil } from 'ramda';
import React, { FC } from 'react';
import { AlertTriangle } from 'react-feather';
import { useUIDSeed } from 'react-uid';
import { SpinnerCircularFixed } from 'spinners-react';

import { H4, Tag, Typography } from '../../../components/Typography';
import { Colors } from '../../../utils/style-utils';
import { Container, Loading } from '../../commonStyles';
import { Entry } from '../types';

interface P {
  progress: Record<string, number | undefined>;
  records: Entry[];
}

export const UploadingContainer: FC<P> = (props) => {
  const seed = useUIDSeed();
  const { progress, records } = props;

  function renderProgress(progress: number | undefined) {
    if (isNil(progress)) {
      return <AlertTriangle color="#eb5757" />;
    }

    if (progress === -1) {
      return (
        <div className="flex items-center">
          <Tag className="mr-4">Preparing...</Tag>
          <SpinnerCircularFixed
            size={30}
            thickness={180}
            speed={180}
            color={Colors.Blue500}
            secondaryColor="rgba(0, 0, 0, 0)"
          />
        </div>
      );
    }

    return <ProgressBar className="progress-bar" width="200px" bgColor={Colors.Blue500} completed={progress} />;
  }

  return (
    <Loading className="flex flex-col items-center justify-center">
      <Container className="w-full">
        <H4 className="mb-20 text-center">Uploading documents</H4>

        {records.map((record) => (
          <div className="flex w-full justify-between items-center my-2" key={seed(record.id)}>
            <div className="max-w-xs">
              <Typography>{record.file?.name}</Typography>
            </div>

            {renderProgress(progress[record.id])}
          </div>
        ))}
      </Container>
    </Loading>
  );
};
