import React, { ReactElement, useState } from 'react';
import { createPortal } from 'react-dom';
import { HelpCircle, Icon as IconType } from 'react-feather';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import { Text } from '../styles/common';
import { Colors } from '../utils/style-utils';
import { useToggle } from '../utils/use-toggle';

const Bubble = styled.div`
  background: #f2f3f5;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2f3144;
  font-size: 16px;
  padding: 20px;
  max-width: 350px;
  width: 100%;
  z-index: 1000;
  position: fixed;

  ${Text} {
    color: #2f3144;
    font-size: 16px;
  }
`;

const POPPER_ROOT = typeof document !== `undefined` ? document.getElementById('popper') : null;

export function HelpTooltip({ text, Icon = HelpCircle }: { text: ReactElement; Icon?: IconType }): JSX.Element {
  const [hover, toggleHover] = useToggle(false);
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
  });

  return (
    <>
      <span className="ml-2" ref={setReferenceElement} onMouseOver={toggleHover} onMouseOut={toggleHover}>
        <Icon size={24} color={Colors.Blue500} />
      </span>

      {hover &&
        POPPER_ROOT &&
        createPortal(
          <Bubble ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {text}
          </Bubble>,
          POPPER_ROOT
        )}
    </>
  );
}
