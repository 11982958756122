import React from 'react';

import { Text } from '../../styles/common';

export const ConventionCitationText = (
  <Text style={{ fontSize: 16 }}>
    The citation convention is text you employ in your parent document’s citations to refer to a particular child
    document. This is usually an acronym, like RT for Reporter’s Transcript, AOB for an opening brief, MJN for a motion,
    or something else. For example, if one of your citations is (1 <b>C.T.</b> pp. 45-56; <b>RT</b> 55:16-17; <b>MJN</b>{' '}
    p. 12; <b>XYZ Dec.</b>), and the child document you are associating a citation convention with is the Clerk’s
    Transcript, you would input C.T. as your citation convention, and nothing else. You would associate the remaining
    conventions (bolded text) with their respective child documents as well.
  </Text>
);

export const ExhibitTabText = (
  <Text style={{ fontSize: 16 }}>
    Check this box when, if referring to this child document in your parent document, you employed Exhibit or Tab
    numbers/letters. This commonly arises when the child document is writ exhibits. For example, (2{' '}
    <b>
      Exh. <span className="text-red-500">B</span>
    </b>
    , <u>pp.</u> 202-204) or (3{' '}
    <b>
      Tab. <span className="text-red-500">10</span>
    </b>
    , <u>pp.</u> 301-310). The citation convention is Exh. or Tab., but checking this box is necessary to let HyperCite
    know that is should “skip” the text in <span className="text-red-500">red font</span>, which are the Exhibit or Tab
    numbers/letters. A final note, when writing citations employing Tab or Exhibits in your parent document, you must in
    your parent document’s citations follow the letter or number with a <u>p.</u> or <u>pp.</u> (underlined in example).
  </Text>
);
