import uniqueId from 'lodash.uniqueid';
import {
  __,
  concat,
  equals,
  filter,
  find,
  gt,
  identity,
  ifElse,
  isEmpty,
  isNil,
  length,
  not,
  pipe,
  propEq,
  propOr,
  reject,
  sort,
  where,
} from 'ramda';
import SparkMD5 from 'spark-md5';

import { Entry } from './types';

export const findMainRecord = find<Entry>(propEq('type', 'main'));
export const findEmptyAttachment = where({
  type: equals('attachment'),
  file: isNil,
});
export const mainRecordFilled: (x: Entry[]) => boolean = pipe<
  Entry[],
  Entry | undefined,
  Entry['file'] | null,
  boolean,
  boolean
>(findMainRecord, propOr(null, 'file'), isNil, not);
export const excludeMainRecord = filter<Entry>(propEq('type', 'attachment'));
export const addPlaceholder = (collection: Entry[]): Entry[] =>
  concat(collection, [
    {
      id: uniqueId('document_'),
      file: null,
      type: 'attachment',
      signedUrl: '',
    },
  ]);
const noAttachments = pipe<Entry[], Entry[], boolean>(excludeMainRecord, isEmpty);
const ensureInput = ifElse(noAttachments, addPlaceholder, identity);
const ensureOnlyOne = ifElse(
  pipe(length, gt(__, 1)),
  reject((entry: Entry) => entry.type === 'attachment' && isNil(entry.file)),
  identity
);
const ensurePlaceholder = ifElse(pipe(findEmptyAttachment, equals(true)), identity, addPlaceholder);
type CompactFn = (x: (Entry | null)[]) => Entry[];
const compact: CompactFn = filter<Entry | null>(Boolean) as unknown as CompactFn;
export const sortById = sort<Entry>((a, b) => {
  const [, aid] = a.id.split('_');
  const [, bid] = b.id.split('_');
  return Number(aid) > Number(bid) ? 1 : -1;
});
export const safeInsert: (x: (Entry | null)[]) => Entry[] = pipe(
  compact,
  ensureInput,
  ensureOnlyOne,
  ensurePlaceholder
);

export function getFileHash(file?: File): Promise<string | null> {
  if (!file) return Promise.resolve(null);

  const blobSlice =
    // @ts-ignore
    File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
  const chunkSize = 2097152; // 2MB

  return new Promise((resolve, reject) => {
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    fileReader.onload = function (e) {
      spark.append(e.target?.result as ArrayBuffer); // Append array buffer
      currentChunk += 1;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        resolve(spark.end());
      }
    };

    fileReader.onerror = function () {
      console.warn('oops, something went wrong.');
      reject(null);
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= Number(file!.size) ? file!.size : start + chunkSize;
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();
  });
}
