import axios from 'axios';
import { classdFn } from 'classd';
import { navigate } from 'gatsby';
import uniqueId from 'lodash.uniqueid';
import { nanoid } from 'nanoid';
import {
  equals,
  filter,
  has,
  identity,
  ifElse,
  isNil,
  map,
  mergeDeepLeft,
  nth,
  prop,
  propEq,
  reject,
  remove,
  values,
} from 'ramda';
import React, { ChangeEvent, VFC, useEffect, useMemo, useRef, useState } from 'react';
import { Info, XOctagon } from 'react-feather';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useUIDSeed } from 'react-uid';

import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { DragAndDrop } from '../../components/DragAndDrop';
import { FileInput } from '../../components/FileInput';
import { HelpTooltip } from '../../components/HelpTooltip';
import { Input } from '../../components/Input';
import { Label } from '../../components/Label';
import { H5, Typography } from '../../components/Typography';
import { FileIcon } from '../../icons/FileIcon';
import { TrashIcon } from '../../icons/TrashIcon';
import { Appendix, createCitation as createCitationAction, saveTemporaryRecords } from '../../lib/actions/documents';
import { getSignedUrl, uploadDocument } from '../../lib/client/fileUpload';
import { Paths } from '../../paths';
import { RootState } from '../../reducers';
import { userIdSelector } from '../../selectors/user';
import { findById, generateGuestToken, getBodyMessage, tryAutomaticDownload } from '../../utils/function-utils';
import { Colors } from '../../utils/style-utils';
import { Cell, Container, HelpText, Warning } from '../commonStyles';
import { SignupForm } from '../SignupForm';
import { CitingContainer, SuccessContainer, UploadingContainer } from './components';
import { excludeMainRecord, findMainRecord, getFileHash, mainRecordFilled, safeInsert, sortById } from './helpers';
import { ConventionCitationText, ExhibitTabText } from './styles';
import { Entry, EntryType } from './types';

enum UploadStep {
  Prepare,
  Upload,
  Citing,
  Account,
  Success,
}

const isPreparing = equals(UploadStep.Prepare);
const isUploading = equals(UploadStep.Upload);
const isCiting = equals(UploadStep.Citing);
const isSuccess = equals(UploadStep.Success);
const isAccount = equals(UploadStep.Account);

type Rejected = PromiseRejectedResult & {
  value: { id: string; name?: string };
};

export const UploadForm: VFC = () => {
  const seed = useUIDSeed();
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const isAnonymous = isNil(userId);
  const formRef = useRef<HTMLFormElement>(null);
  const tmpRecords = useSelector((state: RootState) => state.documents.tmpRecords);
  const [records, setRecords] = useState<Entry[]>(
    tmpRecords ?? [
      { id: uniqueId('document_'), file: null, type: 'main', signedUrl: '' },
      { id: uniqueId('document_'), file: null, type: 'attachment', signedUrl: '' },
    ]
  );

  const [createLocalCopy, setLocalCopy] = useState(false);
  const [formStep, setFormStep] = useState(UploadStep.Prepare);
  const [globalProgress, setGlobalProgress] = useState(0);
  const [documentProgress, setDocumentProgress] = useState<Record<string, number | undefined>>({});
  const [duplicates, setDuplicates] = useState<[string, string][]>([]);
  const [downloadLink, setDownloadLink] = useState<string>('');
  const [appendiciesReferences, setAppendiciesReferences] = useState<(string[] | null)[]>([]);
  const [skipUpload, setSkip] = useState(false);

  const exceptMain = excludeMainRecord(records);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mainRecord = findMainRecord(records)!;

  useEffect(() => {
    if (globalProgress === 100) {
      if (isUploading(formStep)) {
        const t = setTimeout(() => {
          setFormStep(UploadStep.Citing);
          setGlobalProgress(0);
          setDocumentProgress({});
          clearTimeout(t);
        }, 1000);
      } else if (isCiting(formStep)) {
        setFormStep(isAnonymous ? UploadStep.Account : UploadStep.Success);
        setGlobalProgress(0);
      }
    }
  }, [globalProgress]);

  useEffect(() => {
    async function upload() {
      if (isUploading(formStep)) {
        setAppendiciesReferences([]);
        const docsUuid = nanoid(10);
        const anonUserId = `anonymous-${generateGuestToken()}`;
        let clone = [...records];

        await Promise.allSettled(
          records.map(async (document) => {
            try {
              setDocumentProgress((prev) => ({ ...prev, [document.id]: -1 }));
              const result = await getSignedUrl(document.file!.name, docsUuid, userId || anonUserId);

              await uploadDocument({
                data: result.data,
                file: document.file as File,
                onProgress: (progress) => setDocumentProgress((prev) => ({ ...prev, [document.id]: progress })),
              });

              clone = clone.map((record) =>
                record.id === document.id ? ({ ...record, signedUrl: result.url } as Entry) : record
              );

              return true;
            } catch (err) {
              setDocumentProgress((prev) => ({ ...prev, [document.id]: undefined }));
              return Promise.reject({ id: document.id, name: document.file?.name });
            }
          })
        ).then((response) => {
          response.forEach((result) => {
            if (result.status === 'rejected') {
              const defaultMessage = 'Could not upload file ' + (result as Rejected).value.name;
              clone = clone.filter((record) => record.id !== (result as Rejected).value.id);
              toast.error(getBodyMessage(result.reason, defaultMessage), {
                icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
                duration: 10000,
              });
            }
          });
        });

        setGlobalProgress(100);
        setRecords(clone);
        setSkip(true);
      } else if (isCiting(formStep)) {
        dispatch(saveTemporaryRecords(null));
        const brief = records.find((record) => record.type === 'main')!;
        const appendicies = records.filter((record) => record.type !== 'main');
        const briefS3Key = brief.signedUrl!.replace(/(^\w+:|^)\/\//, '');

        try {
          const result = (await dispatch(
            createCitationAction(
              briefS3Key.substring(briefS3Key.indexOf('/') + 1),
              appendicies.map((appendix) => {
                const appendixS3Key = appendix.signedUrl!.replace(/(^\w+:|^)\/\//, '');

                return {
                  appendix_s3_key: appendixS3Key.substring(appendixS3Key.indexOf('/') + 1),
                  appendix_reference: appendix.classification,
                  page_offset: 0,
                  exhibit_num_used: appendix.citing ? 'y' : 'n',
                } as Appendix;
              }),
              !isAnonymous,
              createLocalCopy
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          )) as unknown as any;

          setDownloadLink(result.url);
          setGlobalProgress(100);
          setSkip(false);

          if (!isAnonymous) {
            tryAutomaticDownload(result.url);
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            if (has('appendicies', err.response?.data?.message)) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              setAppendiciesReferences(values((err.response?.data as any).appendicies) as string[][]);
              toast.error('Appendicies missing', {
                icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
                duration: 4000,
              });
              setFormStep(UploadStep.Prepare);
            } else {
              toast.error(getBodyMessage(err, 'Could not hypercite file'), {
                icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
                duration: 10000,
              });
              setFormStep(UploadStep.Prepare);
            }
          } else if (err instanceof Error) {
            toast.error(err.message, {
              icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
              duration: 10000,
            });
          }
        }
      }
    }

    upload();
  }, [formStep]);

  useMemo(() => {
    async function compareFiles() {
      if (records.filter((record) => !isNil(record.file)).length === 0) return;

      // If SSR, no promises are done because getFileHash requires window File class
      const promises =
        typeof window === 'undefined'
          ? []
          : records
              .filter((record) => Boolean(record.file))
              .map((record) => getFileHash(record.file!).then((hash) => [record.id, hash] as [string, string]));

      const pairs: [string, string][] = await Promise.all(promises);
      const ids = map(nth(0), pairs);
      const hashes = map(nth(1), pairs);
      const duplicates: [string, string][] = [];

      for (let j = 0; j < hashes.length; j += 1) {
        const hash = hashes[j];
        if (hashes.indexOf(hash, j + 1) > -1) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          duplicates.push([ids[j]!, ids[hashes.indexOf(hash, j + 1)]!]);
        }
      }

      setDuplicates(duplicates);
    }

    void compareFiles();
  }, [records, records.map(prop('file'))]);

  function getNextDisabled(): boolean {
    const areDocumentsIncomplete =
      !Boolean(findMainRecord(records)?.file) || filter((record: Entry) => Boolean(record.file), exceptMain).length < 1;

    return areDocumentsIncomplete;
  }

  function resetForm() {
    setRecords([
      { id: uniqueId('document_'), file: null, type: 'main' },
      { id: uniqueId('document_'), file: null, type: 'attachment' },
    ]);
    setFormStep(UploadStep.Prepare);
    setDuplicates([]);
    setGlobalProgress(0);
    formRef.current?.reset();
    dispatch(saveTemporaryRecords(null));
  }

  function renderLoading() {
    if (isUploading(formStep)) {
      return <UploadingContainer progress={documentProgress} records={records} />;
    }

    if (isCiting(formStep)) {
      return <CitingContainer progress={globalProgress} />;
    }

    if (isSuccess(formStep)) {
      return <SuccessContainer downloadLink={downloadLink} onReset={resetForm} />;
    }

    return null;
  }

  if (isAccount(formStep)) {
    return (
      <SignupForm
        subtitle="Create a free account to download your file"
        okText="Create Account"
        onComplete={() => {
          setFormStep(UploadStep.Success);
          tryAutomaticDownload(downloadLink);
        }}
      />
    );
  }

  function onUploadClick() {
    if (appendiciesReferences.filter(Boolean).length > 0) {
      toast.error('Please fill out the proper appendicies', {
        icon: <XOctagon className="w-8" size={32} color="#D0312D" />,
      });
      return;
    }
    setRecords(filter((record: Entry) => record.file !== null, records));
    if (isAnonymous) {
      dispatch(saveTemporaryRecords(records));
      void navigate(`${Paths.SignUp}?redirect=${Paths.Home}`);
    } else {
      setFormStep(skipUpload ? UploadStep.Citing : UploadStep.Upload);
    }
  }

  function onDrop(files: FileList) {
    const attachments = Array.from(files).map((file) => ({
      id: uniqueId('document_'),
      file,
      type: 'attachment' as EntryType,
    }));
    setRecords((value) => safeInsert([...value, ...attachments]));
  }

  return (
    <form ref={formRef} className="relative">
      <Container className="flex flex-col flex-1 bg-white w-full">
        <Label>
          Upload Parent Document File <small>(Brief, Petition, Etc.)</small>
        </Label>

        <DragAndDrop
          onDrop={(files) => {
            const [file] = Array.from(files);
            setRecords(map(ifElse(propEq('type', 'main'), mergeDeepLeft({ file }), identity)));
          }}
        >
          <FileInput
            className="mb-6"
            id={mainRecord.id}
            file={mainRecord.file}
            canRemove={isPreparing(formStep)}
            onChange={([, files = null]) =>
              setRecords(map(ifElse(propEq('type', 'main'), mergeDeepLeft({ file: files?.[0] }), identity)))
            }
          />
        </DragAndDrop>

        <Label>
          Upload Child Document File(s) <small>(Transcripts, Prior Briefs, Etc.)</small>
        </Label>

        <DragAndDrop onDrop={onDrop}>
          {sortById(exceptMain).map((entry: Entry, index: number) => {
            const maybeIndex = duplicates.findIndex((pair) => pair[1] === entry.id);
            const hasError = maybeIndex > -1;

            let dupedPair: [Entry, Entry] = [] as unknown as [Entry, Entry];

            if (hasError) {
              dupedPair = duplicates[maybeIndex].map((id) => findById(id, records)!) as [Entry, Entry];
            }

            function onRemoveDuplicate(index: number) {
              return () => {
                const isMain = records.find((record) => record.id === duplicates[maybeIndex][index])!.type === 'main';

                if (isMain) {
                  // Main file cannot be removed, only "resetted"
                  setRecords((prev) => {
                    const main = prev.find((item) => item.type === 'main')!;
                    return [{ ...main, file: null }, ...prev.filter((item) => item.type === 'attachment')];
                  });
                } else {
                  // Attachments are removed
                  setRecords(reject(propEq('id', duplicates[maybeIndex][index])));
                }
              };
            }

            function onFileInputCancel(id: string) {
              setRecords(records.map((record) => (record.id === id ? { ...record, file: null } : record)));
            }

            function onWritPetitionCheck(checked: boolean) {
              setRecords(records.map((record) => (record.id === entry.id ? { ...record, citing: checked } : record)));
            }

            function onClassificationChange(e: ChangeEvent<HTMLInputElement>) {
              setRecords(
                records.map((record) =>
                  record.id === entry.id ? { ...record, classification: e.target.value } : record
                )
              );
            }

            async function onFileInputChange([id, files]: [string, File[] | undefined]) {
              let clone = [...records];
              let idx = clone.findIndex(propEq('id', id));

              if (!files) {
                clone = remove(idx, 1, clone);
              } else if (files.length === 1) {
                clone[idx] = { id, file: files[0], type: 'attachment' as EntryType };
              } else {
                // If SSR, no promises are done because getFileHash requires window File class
                const recordsFilesPromises =
                  typeof window === 'undefined'
                    ? []
                    : records
                        .filter((record) => Boolean(record.file))
                        .map((record) =>
                          getFileHash(record.file!).then((hash) => [record.id, hash] as [string, string])
                        );
                const pairs = await Promise.all(recordsFilesPromises);

                for (let i = 0; i < files.length; i += 1) {
                  const file = files[i];
                  const hash = await getFileHash(file);
                  const recordIdx = pairs.findIndex((pair) => pair[1] === hash);

                  if (recordIdx > -1) {
                    idx = clone.findIndex(propEq('id', pairs[recordIdx][0]));
                    clone[idx] = { id, file, type: 'attachment' as EntryType };
                  } else {
                    clone.push({ id: uniqueId('document_'), file, type: 'attachment' as EntryType });
                  }
                }
              }

              setRecords(safeInsert(clone));
            }

            return (
              <Cell className={classdFn({ fill: !!entry.file, 'mb-4': !!entry.file })} key={seed(entry.id)}>
                <FileInput
                  multiple
                  className="mb-4"
                  hasError={hasError}
                  inline={!!entry.file}
                  canRemove={isPreparing(formStep)}
                  file={entry.file}
                  id={entry.id}
                  placeholder="Add .PDF file(s)"
                  onCancel={onFileInputCancel}
                  onChange={onFileInputChange}
                />

                {!!entry.file && (
                  <div style={{ marginLeft: 60, marginRight: 25 }}>
                    <div className="mb-2 -mt-8 flex flex-row justify-between">
                      <Input
                        style={{ maxWidth: 100 }}
                        defaultValue={entry.classification || ''}
                        onChange={onClassificationChange}
                        error={!!appendiciesReferences[index]}
                        onBlur={() => {
                          if (!isNil(appendiciesReferences[index] && entry.classification)) {
                            const clone = [...appendiciesReferences];
                            clone[index] = null;
                            setAppendiciesReferences(clone);
                          }
                        }}
                        label={
                          <div className="flex items-center">
                            <HelpText className="mr-2">Citation Convention</HelpText>
                            <HelpTooltip text={ConventionCitationText} />
                          </div>
                        }
                        helpText={
                          <HelpText style={{ fontSize: 11, margin: '7px 0 10px' }}>
                            E.g.: “C.T.” “CT” “AOB” “A.R.B.” “MJN” or a custom convention like “Doe Dec.”
                          </HelpText>
                        }
                      />
                    </div>

                    <div className="mb-2 flex items-center">
                      <Checkbox
                        label="I cite to this source using Exhibit or Tab numbers prior to page numbers ie for a writ petition"
                        defaultChecked={entry.citing}
                        onChange={onWritPetitionCheck}
                      />
                      <HelpTooltip text={ExhibitTabText} />
                    </div>
                  </div>
                )}

                {hasError && (
                  <Warning className="flex flex-1 flex-col mt-6 -mb-4">
                    <H5 className="mb-4" style={{ fontSize: 16 }}>
                      We found duplicates
                    </H5>

                    {dupedPair.map((element: Entry, index) => (
                      <div key={seed(element)} style={{ height: 70 }} className="flex flex-row items-center">
                        <FileIcon />

                        <Typography className="ml-1 mr-auto">{element.file?.name}</Typography>

                        <Button className="mr-2" onClick={onRemoveDuplicate(+!index)}>
                          Keep
                        </Button>

                        <Button icon variant="tertiary" onClick={onRemoveDuplicate(index)}>
                          <TrashIcon fill={Colors.Blue500} />
                        </Button>
                      </div>
                    ))}
                  </Warning>
                )}
              </Cell>
            );
          })}
        </DragAndDrop>

        {isPreparing(formStep) && (
          <div className="flex flex-row justify-between mt-4">
            <div className="w-full sm:w-8/12 flex items-center">
              <Checkbox onChange={(checked) => setLocalCopy(checked)} />

              <div className="ml-4 flex items-center">
                <Typography style={{ fontFamily: "'Norse', sans-serif", fontSize: '0.875rem', lineHeight: '1.25rem' }}>
                  Download an offline version instead
                </Typography>
                <HelpTooltip
                  Icon={Info}
                  text={
                    <Typography
                      style={{ fontFamily: "'Norse', sans-serif", fontSize: '0.875rem', lineHeight: '1.25rem' }}
                    >
                      Without selecting this box, your parent document's citations will direct readers to refrence documents contained within our cloud. This allows for e-filing to occur and is our default output. Checking this box, you will recieve your parent document where citaitons direct readers to PDF attachments instead of the cloud (larger file size).
                    </Typography>
                  }
                />
              </div>
            </div>

            <div className="flex flex-col items-end justify-between">
              <Button className="ml-auto" disabled={getNextDisabled()} onClick={onUploadClick}>
                Upload
              </Button>

              {mainRecordFilled(records) && filter((record: Entry) => Boolean(record.file), exceptMain).length > 0 && (
                <HelpText className="mt-2" positive>
                  All files added
                </HelpText>
              )}
            </div>
          </div>
        )}
      </Container>

      {renderLoading()}
    </form>
  );
};
