import classd, { classdFn } from 'classd';
import { isNil } from 'ramda';
import React, { ChangeEvent, FC, FocusEvent, InputHTMLAttributes, MouseEvent, useRef } from 'react';

import { AddFileIcon } from '../../icons/AddFileIcon';
import { FileIcon } from '../../icons/FileIcon';
import { TrashIcon } from '../../icons/TrashIcon';
import { Label } from '../Label';
import { renderHelpText } from './helpers';
import { ButtonContainer, Container, InputContent, InputFacade, PlaceholderIcon, TextInput } from './styles';
import { FileInputProps } from './types';

export const FileInput: FC<FileInputProps> = (props) => {
  const {
    label = '',
    helpText = '',
    hasError = false,
    canRemove = true,
    inline = false,
    multiple = false,
    onChange,
    onCancel,
    file,
    id,
    className,
    placeholder = 'Add .PDF file',
    ...rest
  } = props;
  const ref = useRef<HTMLInputElement>(null);
  const empty = isNil(file);
  const containerClassName = classdFn({ error: hasError, empty, inline });
  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...rest,
    id,
    multiple,
    type: 'file',
    accept: '.pdf',
    readOnly: !canRemove,
    placeholder: 'Add file',
    className: 'cursor-pointer invisible w-full',
  };

  function onInputClick(event: MouseEvent<HTMLInputElement>) {
    if (!canRemove) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if ((event.target as HTMLInputElement).value.length === 0) {
      onCancel?.(id);
    }
  }

  function onInputBlur(event: FocusEvent<HTMLInputElement>) {
    if (event.target.value.length === 0) {
      onCancel?.(id);
    }
  }

  function onInputChange(event: ChangeEvent<HTMLInputElement>) {
    const files = Array.from(event.target.files || []);
    onChange([id as string, files]);
  }

  function onRemoveClick(): void {
    onChange([id as string, undefined]);
  }

  return (
    <Container className={containerClassName}>
      {label && <Label htmlFor={id}>{label}</Label>}

      <div className={classd`${className} relative w-auto`}>
        <PlaceholderIcon>{empty ? <AddFileIcon /> : <FileIcon />}</PlaceholderIcon>

        <InputFacade onClick={() => ref.current && ref.current.click()}>
          <InputContent>{empty ? placeholder : file!.name}</InputContent>

          <TextInput {...inputProps} ref={ref} onClick={onInputClick} onBlur={onInputBlur} onChange={onInputChange} />
        </InputFacade>

        {!empty && canRemove && (
          <ButtonContainer>
            <button className="bg-transparent border-none" onClick={onRemoveClick}>
              <TrashIcon />
            </button>
          </ButtonContainer>
        )}
      </div>

      {renderHelpText(helpText)}
    </Container>
  );
};
