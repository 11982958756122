import React, { FC } from 'react';

import { ProgressRing } from '../../../components/ProgressRing';
import { H4, H5 } from '../../../components/Typography';
import { Container, Loading } from '../../commonStyles';

interface P {
  progress: number;
}

export const CitingContainer: FC<P> = (props) => {
  const { progress } = props;

  return (
    <Container>
      <Loading className="flex flex-col items-center justify-center">
        <H4 className="mb-20 text-center">
          HyperCite<sup>&reg;</sup> is creating document
        </H4>

        <ProgressRing progress={progress} />

        <H5 className="mt-6 text-center">Citing files...</H5>
      </Loading>
    </Container>
  );
};
