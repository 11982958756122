import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { toPairs } from 'ramda';

import { Config } from '../../config';

type GetSignedUrlResponse = {
  url: string;
  data: {
    url: string;
    fields: Record<string, string>;
  };
};

type UploadDocumentParams = {
  data: GetSignedUrlResponse['data'];
  file: File;
  onProgress?: (progress: number) => void;
};
 
export function uploadDocument({ data, file, onProgress }: UploadDocumentParams): AxiosPromise {
  const fd = new FormData();
  const entries = toPairs(data.fields);

  for (let i = 0; i < entries.length; i += 1) {
    const pair = entries[i];
    fd.append(pair[0], pair[1]);
  }

  fd.append('file', file, file.name);

  const options: AxiosRequestConfig = {};

  if (onProgress) {
    options.onUploadProgress = (evt: ProgressEvent) => {
      onProgress(Math.round((evt.loaded * 100) / evt.total));
    };
  }

  return axios.post(data.url, fd, options);
}

export function getSignedUrl(
  fileName: string,
  uuid: string,
  userId: number | string | null
): Promise<GetSignedUrlResponse> {
  return axios
    .post<GetSignedUrlResponse>(Config.AuthApiUrl + '/sign-s3', {
      file_name: `${userId}/${uuid}/${fileName}`,
    })
    .then((res) => res.data);
}
