import React, { FC } from 'react';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components';

import { Colors } from '../utils/style-utils';
import { H5 } from './Typography';

const Percentage = styled(H5)`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  margin: -15px 0px 0px -30px;
  z-index: 3;
  width: 60px;
  text-align: center;
  z-index: 3;
`;

const Wrapper = styled.div`
  margin-left: -100px;
  margin-top: -100px;
`;

interface ProgressRingProps {
  progress: number;
}

export const ProgressRing: FC<ProgressRingProps> = (props) => {
  const { progress } = props;

  return (
    <div className="relative h-3/6">
      <Wrapper className="absolute left-1/2 top-2/4">
        <SpinnerCircular
          size={200}
          thickness={100}
          speed={180}
          color={Colors.Blue500}
          secondaryColor="rgba(0, 0, 0, 0)"
        />
      </Wrapper>
      <Percentage>{progress}%</Percentage>
    </div>
  );
};
